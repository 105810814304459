import React from 'react';
import PromoBlockDiscount from './promo-block-discount';
import PromoBlockCertificate from './promo-block-certificate';
import PromoBlockSanta from "./promo-block-santa";

import { ReactComponent as MyOrders } from '../../svg/my-orders.svg';
import { useAuthorized } from '../../ducks/auth';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import PromoBlockWorkTime from "./promo-block-work-time";
import PromoBlockPickup from "./promo-block-pickup";
import PromoBlockLoyalty from "./promo-block-loyalty";
import PromoBlockValentine from "./promo-block-valentine";
import PromoBlockMargherita from "./promo-block-margherita";

function Promo() {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const isAuthorized = useAuthorized();

    const handleClick = () => {
        navigate('/account');
    };

    return (
        <div className='promo'>
            <div className='promo__mobile-inner'>
                <PromoBlockLoyalty />
                <PromoBlockDiscount />

                {/*<PromoBlockSanta />*/}
                {/*<PromoBlockWorkTime />*/}
                {/*<PromoBlockValentine />*/}
                {/*<PromoBlockPickup />*/}
                {
                    !isAuthorized ? null :
                        <div className='promo__orders effect' onClick={handleClick}>
                            <MyOrders />
                            <div className='promo__orders-text'>
                                {t('common.myOrders')}
                            </div>
                        </div>
                }
                <PromoBlockMargherita />
                {/*<PromoBlockDiscount />*/}
            </div>
            <div className='promo__desktop-inner'>
                {/*<PromoBlockSanta />*/}
                {/*<PromoBlockWorkTime />*/}
                <PromoBlockLoyalty />
                <PromoBlockDiscount />
                {/*<PromoBlockValentine />*/}
                {/*<PromoBlockPickup />*/}
                {/*<PromoBlockDiscount />*/}
                <PromoBlockMargherita />
            </div>
        </div>
    );
}

export default Promo;
