export const CATEGORIES = [
  // {
  //   id: '13',
  //   anchorSelf: 'merch',
  //   anchor: '#merch',
  //   name: 'Мерч',
  //   name_en: 'Merch',
  //   decoration: null
  // },
  {
    id: '1',
    anchorSelf: 'pizza',
    anchor: '#pizza',
    name: 'Пицца',
    name_en: 'Pizza',
    decoration: null
  },
  // {
  //   id: '8',
  //   anchorSelf: 'santa_pizza',
  //   anchor: '#santa_pizza',
  //   name: 'SantaPizza',
  //   name_en: 'SantaPizza',
  //   decoration: true
  // },
  {
    id: '6',
    anchorSelf: 'soup',
    anchor: '#soup',
    name: 'Супы',
    name_en: 'Soups',
    decoration: null
  },
  {
    id: '2',
    anchorSelf: 'salad',
    anchor: '#salad',
    name: 'Салаты',
    name_en: 'Salads',
    decoration: null
  },
  {
    id: '5',
    anchorSelf: 'drink',
    anchor: '#drink',
    name: 'Напитки',
    name_en: 'Drinks',
    decoration: null
  },
  {
    id: '4',
    anchorSelf: 'dessert',
    anchor: '#dessert',
    name: 'Десерты',
    name_en: 'Desserts',
    decoration: null
  },
  {
    id: '9',
    anchorSelf: 'certificate',
    anchor: '#certificate',
    name: 'Сертификаты',
    name_en: 'Certificate',
    decoration: null
  },
  {
    id: '12',
    anchorSelf: 'valentine',
    anchor: '#valentine',
    name: '14 Февраля',
    name_en: '14 Февраля',
    decoration: true
  },
];

export const GIFT_CATEGORIES = [
  {
    id: '10',
    name: 'Десерт в подарок',
    isFreeDesert: true,
    isFreePizza: false
  },
  {
    id: '11',
    name: 'Пицца в подарок',
    isFreeDesert: false,
    isFreePizza: true
  }
];

export const FEATURE_NAMES = {
  spicy: 'Острая',
  vegetarian: 'Вегетарианская',
  vegan: 'Веганская',
  fish: 'Рыбная'
};

export const FEATURE_NAMES_EN = {
  spicy: 'Spicy',
  vegetarian: 'Vegetarian',
  vegan: 'Vegan',
  fish: 'Fish'
};

export function getDateString(d) {
  const datePart = d.split(' ')[0]
  const timePart = d.split(' ')[1]

  const dateParts = datePart.split('-');
  const timeParts = timePart.split(':');

  const day = dateParts[2];
  const month = dateParts[1];
  const year = dateParts[0];

  const hour = timeParts[0];
  const minute = timeParts[1];

  return `${day}.${month}.${year} в ${hour}:${minute}`;
}

export function checkDishInCart(cart, idx) {
  let amount = 0;
  if (idx !== undefined && cart[idx] !== undefined) {
    amount = cart[idx].amount;
  }
  return amount;
}

export function checkDishExistInCart(cart, id) {
  let amount = 0;
  const idx = cart.findIndex((item) => item.id === id);
  if (idx > -1 && cart[idx] !== undefined) {
    amount = cart[idx].amount;
  }
  return amount;
}

export function additionIsSelected(cart, idx, additionId) {
  let selected = false;
  if (idx !== undefined && cart[idx] !== undefined) {
    const dish = cart[idx];
    if (dish.additions) {
      dish.additions.forEach((t) => {
        if (additionId === t.additionId) selected = true;
      })
    }
  }
  return selected;
}

export function getUserPhone(userData) {
  let value = '';

  if (userData && userData.phone && userData.phone[0] && userData.phone[0].value) {
    value = userData.phone[0].value;
  }

  return value;
}

export function getUserEmail(userData) {
  let value = '';

  if (userData && userData.email && userData.email[0] && userData.email[0].value) {
    value = userData.email[0].value;
  }

  return value;
}

export function getUserEditedPhone(userData) {
  let value = '';

  if (userData && userData.phone && userData.phone[0] && userData.phone[0].value) {
    value = userData.phone[0].value;
  }

  if (value) {
    if (value.charAt(0) === '+') {
      value = value.substring(1);
    }

    // check length?
    if (value.charAt(0) === '7') {
      value = value.substring(1);
    } else if (value.charAt(0) === '8') {
      value = value.substring(1);
    }
  }

  return '7' + value;
}

export function getUserAddresses(userData) {
  const addresses = userData && userData.address ? makeItemsArrayFromObject(userData.address) : [];
  return addresses.filter((a) => a.ext === 'shipping');
}

export function makeItemsArrayFromObject(obj) {
  const arr = [];
  for (const prop in obj) {
    arr.push(obj[prop]);
  }
  return arr;
}

export function makeOrdersItemsArrayWithCatagory(itemsArray, dishList) {
  let items = [];
  for (const item of itemsArray) {
    if (dishList[item.product_id] !== undefined) {
      items.push(dishList[item.product_id])
    } else if (item.product_id === '53') {
      items.push({ product_id: '53', category_id: "1" } );
    }
  }

  return items;
}

const orderStatuses = [
    {
        'id': 'new',
        'name': 'Новый'
    },
    {
        'id': 'processing',
        'name': 'Подтвержден'
    },
    {
        'id': 'auth',
        'name': 'Средства заблокированы'
    },
    {
        'id': 'paid',
        'name': 'Оплачен'
    },
    {
        'id': 'otpravlen-v-rest',
        'name': 'Отправлен в ресторан'
    },
    {
        'id': 'prinyat-restoran',
        'name': 'Принят рестораном'
    },
    {
        'id': 'prigotovlen-rest',
        'name': 'Приготовлен рестораном'
    },
    {
        'id': 'shipped',
        'name': 'Доставляется'
    },
    {
        'id': 'completed',
        'name': 'Выполнен'
    },
    {
        'id': 'refunded',
        'name': 'Возврат'
    },
    {
        'id': 'deleted',
        'name': 'Аннулирован'
    }
];

export function getOrderStatus(status) {
  let result = status;
  orderStatuses.forEach((s) => {
    if (s.id === status) result = s.name;
  })
  return result;
}

export function checkDate(date) {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentDay = currentDate.getDate();
  const dateParts = date.split('.');
  const inputDay = +dateParts[0];
  const inputMonth = +dateParts[1];
  const inputMonthEqualCurrent = inputMonth === currentMonth;
  const inputDayBeforeCurrent = inputDay < currentDay;
  const inputMonthBeforeCurrent = inputMonth < currentMonth;

  if (inputMonthEqualCurrent) {
    if (inputDayBeforeCurrent) {
      return false;
    }
  } else if (inputMonthBeforeCurrent) {
    return false;
  }

  return true;
}

export function getCurrentDatePlacehoder() {
  const date = new Date();
  let month = date.getMonth() + 1;
  let day = date.getDate() + 1;
  if (day < 10) day = '0' + day;
  if (month < 10) month = '0' +  month;
  return `${day}.${month}`;
}

export function findAddressById(addresses, id) {
  let address = {};
  addresses.forEach((a) => {
    if (a.id === id) address = a;
  })
  return address.data || {};
}

export function cleanAddress(s) {
  s = s.replace('Россия, ', '');
  s = s.replace('Москва, ', '');
  return s.trim();
}

export function cleanPhone(p) {
  p = p.replace(/\D/g, '');
  return p;
}

export const DAYS = [
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31'
];

export const MONTHS = {
  'января': '01',
  'февраля': '02',
  'марта': '03',
  'апреля': '04',
  'мая': '05',
  'июня': '06',
  'июля': '07',
  'августа': '08',
  'сентября': '09',
  'октября': '10',
  'ноября': '11',
  'декабря': '12'
};

export const MONTHS_OBJ = {
  '01': 'января',
  '02': 'февраля',
  '03': 'марта',
  '04': 'апреля',
  '05': 'мая',
  '06': 'июня',
  '07': 'июля',
  '08': 'августа',
  '09': 'сентября',
  '10': 'октября',
  '11': 'ноября',
  '12': 'декабря'
}

export const MONTHS_ARRAY = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря'
];

export const MONTHS_EN = {
  'january': '01',
  'february': '02',
  'martha': '03',
  'april': '04',
  'may': '05',
  'june': '06',
  'july': '07',
  'august': '08',
  'september': '09',
  'october': '10',
  'november': '11',
  'december': '12'
};

export const MONTHS_OBJ_EN = {
  '01': 'january',
  '02': 'february',
  '03': 'martha',
  '04': 'april',
  '05': 'may',
  '06': 'june',
  '07': 'july',
  '08': 'august',
  '09': 'september',
  '10': 'october',
  '11': 'november',
  '12': 'december'
}

export const MONTHS_ARRAY_EN = [
  'january',
  'february',
  'martha',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december'
];

export function getCurrentDay() {
  const date = new Date();
  let day = date.getDate();
  if (day < 10) day = '0' + day;
  return day;
}

export function getNextDay() {
  const date = new Date();
  let day = date.getDate() + 1;
  if (day < 10) day = '0' + day;
  return day;
}

export function getCurrentMonth() {
  const date = new Date();
  let month = date.getMonth() + 1;
  if (month < 10) month = '0' +  month;
  return MONTHS_OBJ[month];
}

export function getCurrentMonthEn() {
  const date = new Date();
  let month = date.getMonth() + 1;
  if (month < 10) month = '0' +  month;
  return MONTHS_OBJ_EN[month];
}

export function getCurrentMonthNumber() {
  const date = new Date();
  let month = date.getMonth() + 1;
  if (month < 10) month = '0' +  month;
  return month;
}

export function getCurrentYear() {
  const date = new Date();
  return date.getFullYear();
}

export function getDateStringForPromocode() {
  const year = getCurrentYear();
  const month = getCurrentMonthNumber();
  const day = getCurrentDay();
  return `${year}-${month}-${day}`;
}

export function getYears() {
  const date = new Date();
  const currentYear = date.getFullYear();
  const nextYear = currentYear + 1;
  return [`${currentYear}`, `${nextYear}`];
}

export const INTERVALS = [
  '11:00-11:30', /* 0 */
  '11:30-12:00', /* 1 */
  '12:00-12:30', /* 2 */
  '12:30-13:00', /* 3 */
  '13:00-13:30', /* 4 */
  '13:30-14:00', /* 5 */
  '14:00-14:30', /* 6 */
  '14:30-15:00', /* 7 */
  '15:00-15:30', /* 8 */
  '15:30-16:00', /* 9 */
  '16:00-16:30', /* 10 -12 */
  '16:30-17:00', /* 11 -11 */
  '17:00-17:30', /* 12 -10 */
  '17:30-18:00', /* 13 -9 */
  '18:00-18:30', /* 14 -8 */
  '18:30-19:00', /* 15 -7 */
  '19:00-19:30', /* 16 -6 */
  '19:30-20:00', /* 17 -5 */
  '20:00-20:30', /* 18 -4 */
  '20:30-21:00', /* 19 -3 */
  '21:00-21:30', /* 20 -2 */
  '21:30-22:00'  /* 21 -1 */
];

export const INTERVALS_NY = [
  '11:00-12:00', /* 0 */
  '12:00-13:00', /* 1 */
  '13:00-14:00', /* 2 */
  '14:00-15:00', /* 3 */
  '15:00-16:00', /* 4 */
  '16:00-17:00', /* 5 */
  '17:00-18:00', /* 6 */
  '18:00-19:00', /* 7 */
  '19:00-20:00', /* 8 */
  '20:00-21:00', /* 9 */
  '21:00-22:00', /* 10 */
];


export function getItemsFromOrder(orderItems, dishesObject) {
  const orderItemsWithousToppings = [];

  // get only products not toppings (services)
  for (const itemOrderId in orderItems)
  {
    const productId = orderItems[itemOrderId].product_id;
    if (dishesObject[productId] !== undefined && orderItems[itemOrderId].type === 'product') {
      orderItemsWithousToppings.push(orderItems[itemOrderId]);
    }

    // orderItems[itemOrderId].product_id
    // orderItems[itemOrderId].sku_id
    // orderItems[itemOrderId].service_id

    // if (orderItems[itemOrderId].type === 'service') {
    //
    // }
  }

  return orderItemsWithousToppings.map((item) => {
    return { ...dishesObject[item.product_id], amount: item.quantity };
  });
}

export function getTimeRange(range) {
  const timeRange = range.split('-');
  const timeRangeStartSrc = timeRange[0].split(':');
  const timeRangeEndSrc = timeRange[1].split(':');
  const timeRangeStart = parseInt(timeRangeStartSrc[0]) * 60 + parseInt(timeRangeStartSrc[1]);
  const timeRangeEnd = parseInt(timeRangeEndSrc[0]) * 60 + parseInt(timeRangeEndSrc[1]);

  return { start: timeRangeStart, stop: timeRangeEnd };
}

export function checkIfKitchenIsClosed(range) {
  if (range === 'holiday' || range === 'выходной')
  {
    return true;
  }

  const d = new Date();
  const now = d.getHours() * 60 + d.getMinutes();
  const { start, stop } = getTimeRange(range);

  return now < start || now > stop;
}

export function checkIfDeliveryIsClosed() {
  let start = 10 * 60;
  let stop =  21 * 60 + 30; /* 21:30 */
  const date = new Date();
  const now = date.getHours() * 60 + date.getMinutes();

  /* 31.12 */
  if (date.getMonth() === 11 && date.getDate() === 31) {
    stop = 18 * 60;
  }

  /* 01.01 */
  if (date.getMonth() === 0 && date.getDate() === 1) {
    return true;
  }

  /* 02.01 */
  if (date.getMonth() === 0 && date.getDate() === 2) {
    start = 14 * 60;
  }

  /* 03.01 */
  if (date.getMonth() === 0 && date.getDate() === 3) {
    start = 12 * 60;
  }

  /* 04.01 */
  if (date.getMonth() === 0 && date.getDate() === 3) {
    start = 12 * 60;
  }

  return !(now >= start && now <= stop);
}

export function getTimePart(time) {
  const timeIntervals = time.split('-');
  return timeIntervals[0];
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function validatePickupDate(timestamp, time, range) {
  if (range === 'holiday' || range === 'выходной')
  {
    return false;
  }

  const currentDate = new Date();
  const selectedDate = new Date(timestamp);
  const parsedTime = time.split(':');
  const selected = (parseInt(parsedTime[0]) * 60 + parseInt(parsedTime[1]));
  let { start, stop } = getTimeRange(range);

  /* 31.12 */
  if (selectedDate.getMonth() === 11 && selectedDate.getDate() === 31) {
    stop = 18 * 60;
  }

  /* 01.01 */
  if (selectedDate.getMonth() === 0 && selectedDate.getDate() === 1) {
    return false;
  }

  /* 02.01 */
  if (selectedDate.getMonth() === 0 && selectedDate.getDate() === 2) {
    start = 14 * 60;
  }

  /* 03.01 */
  if (selectedDate.getMonth() === 0 && selectedDate.getDate() === 3) {
    start = 12 * 60;
  }

  if ((selected <= start) || (selected > stop)) {
    return false;
  }

  if (currentDate.getDate() === selectedDate.getDate() &&
    currentDate.getMonth() === selectedDate.getMonth() &&
    currentDate.getFullYear() === selectedDate.getFullYear()) {

    if (selected < currentDate.getHours() * 60) {
      return false;
    }
  }

  return true;
}

export function calcDiscountWithoutSpecialProducts(cart) {
  let price = 0;
  const excluded = ['8', '9'];

  if (cart.length) {
    cart.forEach((entity) => {
      let entityPrice = 0;
      if (excluded.indexOf(entity.category_id) === -1) {
        entityPrice = entity.price;
      }
      if (entity.additions) {
        entity.additions.forEach((t) => {
          entityPrice += t.additionPrice;
        })
      }
      price += entityPrice * entity.amount;
    })
  }

  return (5 / 100) * price;
}

export function checkIfFivePercentDiscountIsActive(values, paymentBlock) {
  const oneDay = 60 * 60 * 24 * 1000;
  const timeToCheck = getTimePart(values.time);
  const monthPart = paymentBlock ? values.month : MONTHS[values.month];
  const selectedDate = Date.parse(`${values.year}-${monthPart}-${values.day}T${timeToCheck}:00`);
  const now = +new Date();
  const selectedDayDifference = selectedDate - now;
  return selectedDayDifference >= oneDay;
}

export function checkIfTenPercentDicsountIsActive() {
  const start = 11 * 60;
  const end = 16 * 60;
  const date = new Date();
  const now = date.getHours() * 60 + date.getMinutes();
  const day = date.getDay();
  return (start <= now && now <= end) && day >=1 && day <=4;
}

export function checkIfOrderDataValid(order) {
  const pickupValidOptions = ['pokrovka', 'mitnaya', 'petrovka', 'gruzinka', 'depo', 'ermolaevskiy'];
  if (order.pickup !== '') {
    return pickupValidOptions.indexOf(order.pickup) !== -1;
  } else {
    return !(order.address === '' || (order.deliveryCost === 0 && !order.freeShipping));
  }
}

export function roundPrice(price) {
  return +(Math.round(price + 'e+2') + 'e-2');
}

export const PRODUCTS = {
  60: 'pizza_salmon_special',
  7: 'pizza_emiliana',
  5: 'pizza_calvetti',
  2: 'pizza_diavola',
  16: 'pizza_prosciutto_funghi',
  14: 'pizza_polla',
  3: 'pizza_piccante',
  11: 'pizza_nordica',
  4: 'pizza_tonno_cipolla',
  10: 'pizza_napoli',
  49: 'pizza_sicilia',
  42: 'pizza_four_seasons',
  20: 'pizza_tartufina',
  15: 'pizza_portofino',
  12: 'pizza_parmigiana',
  21: 'pizza_vegana',
  18: 'pizza_stracciatella',
  9: 'pizza_milky_way',
  19: 'pizza_sweet_blues',
  17: 'pizza_queen_margherita',
  8: 'pizza_margherita',
  6: 'pizza_cosacca',
  23: 'pizza_focaccia',
  25: 'salad_house',
  48: 'salad_caesar',
  26: 'soup_mushroom',
  27: 'soup_pumpkin',
  64: 'soup_tomato',
  63: 'soup_cauliflower',
  34: 'desert_tiramisu',
  52: 'desert_cheesecake',
  43: 'drink_straw_mint',
  44: 'drink_black_rosem',
  37: 'drink_coca',
  39: 'drink_coca_0',
  36: 'drink_water_sparkl',
  40: 'drink_water_still',
  41: 'drink_juice_cherry',
  38: 'drink_juice_apple'
};

export const TOPPINGS = {
  2: 'protein_salami',
  4: 'protein_salmon',
  5: 'cheesу_parmegano',
  6: 'cheese_blue',
  13: 'chesse_mozarella',
  14: 'chesse_ricotta',
  7: 'protein_tuna_conserv',
  8: 'nut_almond',
  9: 'veg_onion_red',
  10: 'veg_pepper_chili',
  11: 'protein_chiken',
  15: 'mushroom_regular',
  16: 'mushroom_cream',
  17: 'sauce_tomato',
  18: 'veg_tomato_cherry',
  22: 'veg_artichoke',
  23: 'veg_tomato_dried',
  24: 'sauce_caesar',
  25: 'veg_capers',
  26: 'veg_olives',
  27: 'veg_salad_romano',
  28: 'veg_salad_arugula',
  29: 'protein_tuna_sesame',
}

export const paymentMethods = {
  EMPTY: 0,
  CASH: 1,
  CARD: 2,
  INVOICE: 3,
  TEST: 4
}

export const paymentMethodsOptions = {
  EMPTY: 0,
  ADD_POINTS: 1,
  WRITE_OFF_POINTS: 2
}

export function paymentMethodString(methodId)
{
  if (methodId === paymentMethods.CASH) {
    return 'cash';
  } else if (methodId === paymentMethods.CARD) {
    return 'card';
  } else if (methodId === paymentMethods.INVOICE) {
    return 'invoice';
  }
  return 'undefined';
}

export const notificationType = {
  EMPTY: 0,
  LEVEL: 1,
  JOIN: 2,
  REGISTERED: 3
}

export const MILISEC_IN_ONE_HOUR = 3600000;

export function birthdayValid(userData) {
  if (userData.birthday === undefined) {
    return  false;
  }
  if (userData.birthday.length) {
    let date = userData.birthday.split('.');
    return date.length === 3
  } else {
    return false;
  }
}

export function collectOrderParamsForAmplitude(order, items) {
  const apmlitudeParams = {
    user_name: order.name,
    user_cell: cleanPhone(order.phone),
    'user_e-mail': order.email
  };

  let orderTotal = 0;
  items.forEach((item) => {
    let additionsPrice = 0;

    if (item.additions && item.additions.length) {
      item.additions.forEach((a) => {
        additionsPrice += a.additionPrice;
      });
    }

    orderTotal += (item.amount * item.price) + additionsPrice;
  });

  apmlitudeParams.order_total = orderTotal;

  if (order.pickup === 'mitnaya') {
    apmlitudeParams.delivery_type = 'pick_up_mytnaya';
  } else if (order.pickup === 'pokrovka') {
    apmlitudeParams.delivery_type = 'pick_up_pokrovka';
  } else if (order.pickup === 'petrovka') {
    apmlitudeParams.delivery_type = 'pick_up_petrovka';
  } else if (order.pickup === 'ermolaevskiy') {
    apmlitudeParams.delivery_type = 'pick_up_ermolaevskiy';
  } else {
    apmlitudeParams.delivery_type = 'courier';
  }

  if (order.comment) {
    apmlitudeParams.order_comments = order.comment;
  }

  if (order.paymentMethod === paymentMethods.CARD) {
    apmlitudeParams.payment_type = 'card';
  } else if (order.paymentMethod === paymentMethods.CASH) {
    apmlitudeParams.payment_type = 'cash';
  } else if (order.paymentMethod === paymentMethods.INVOICE) {
    apmlitudeParams.payment_type = 'invoice';
  }

  return apmlitudeParams;
}

export function collectOrderParamsForFacebook(items) {
  let facebookParams = {};
  let orderTotal = 0;

  items.forEach((item) => {
    let additionsPrice = 0;

    if (item.additions && item.additions.length) {
      item.additions.forEach((a) => {
        additionsPrice += a.additionPrice;
      });
    }

    orderTotal += (item.amount * item.price) + additionsPrice;
  });

  facebookParams.value = orderTotal;
  facebookParams.currency = 'RUB';

  return facebookParams;
}

export function getLoCardsLevel(userInfo, locale) {
  if (userInfo && userInfo.loyalty_level && locale) {
    const level = userInfo.loyalty_level?? 1;
    const map = {
      1: {
        'en': {
          name: 'Fan',
          name_alt: 'Fan',
        },
        'en-GB': {
          name: 'Fan',
          name_alt: 'Fan',
        },
        'ru': {
          name: 'Любитель',
          name_alt: 'Любителя',
        },
        'ru-RU': {
          name: 'Любитель',
          name_alt: 'Любителя',
        }
      },
      2: {
        'en': {
          name: 'Enthusiast',
          name_alt: 'Enthusiast',
        },
        'en-GB': {
          name: 'Enthusiast',
          name_alt: 'Enthusiast',
        },
        'ru': {
          name: 'Энтузиаст',
          name_alt: 'Энтузиаста',
        },
        'ru-RU': {
          name: 'Энтузиаст',
          name_alt: 'Энтузиаста',
        }
      },
      3: {
        'en': {
          name: 'Hedonist',
          name_alt: 'Hedonist',
        },
        'en-GB': {
          name: 'Hedonist',
          name_alt: 'Hedonist',
        },
        'ru': {
          name: 'Гедонист',
          name_alt: 'Гедониста',
        },
        'ru-RU': {
          name: 'Гедонист',
          name_alt: 'Гедониста',
        }
      }
    }

    return {
      loyaltyLevelName: map[level][locale].name,
      loyaltyNextLevel: level < 3? map[level + 1][locale].name_alt : map[3][locale].name_alt
    };
  } else {
    return {
      loyaltyLevelName: 'Загрузка...',
      loyaltyNextLevel: 'Загрузка...',
    };
  }
}
