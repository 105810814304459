export const TRANSLATIONS_RU = {
  notification: {
    level: {
      title: 'Поздравляем!',
      text1: 'Вы перешли на следующий уровень программы лояльности.',
      text2: 'Теперь с каждого заказа вы можете копить {{percent}}% от суммы чека в виде баллов.',
      text3: 'С подробным описанием уровней можно ознакомиться в личном кабинете.',
      link: 'Перейти в личный кабинет'
    },
    join: {
      title: 'Ciao!',
      text: 'Мы запустили программу лояльности, где вы сможете копить и списывать баллы, а еще получите доступ к нашим специальным предложениям.',
      link: 'Завершить регистрацию'
    },
    registered: {
      title: 'Вы успешно зарегистрировались в программе лояльности Maestrello!',
      text: 'Начислили на ваш бонусный счет 300 баллов, которыми можно оплатить до 30% суммы заказа.',
      footerText: 'Buon appetito!'
    }
  },
  offer: {
    label: 'Акции',
    header: 'Приведи друга – получи баллы',
    description: 'Пригласи друга в программу лояльности Maestrello и получи 300 баллов в подарок после его первого заказа',
    link: 'Скопировать ссылку для друга',
    link_copied: 'Ссылка скопирована'
  },
  levels: {
    levelChanged: {
      congratulationsTitle: 'Поздравляем!',
      congratulationsLine1: 'Вы перешли на следующий уровень программы лояльности.',
      congratulationsLine2: 'Теперь с каждого заказа вы можете копить {{percent}}% от суммы чека в виде баллов. А еще дарим вам {{extra}} бонусных баллов! Класс! Спасибо 💛'
    },
    one: {
      label: 'Любитель',
      label_alt: 'Любителя',
      head_text: '5% от суммы заказа вернутся баллами',
      bonus: '300 бонусных баллов за регистрацию'
    },
    two: {
      label: 'Энтузиаст',
      label_alt: 'Энтузиаста',
      head_text: '7% от суммы заказа вернутся баллами',
      bonus: '400 бонусных баллов за переход на уровень',
      info: 'Энтузиастом можно стать, когда сумма чеков достигнет 20 000 рублей без учета стоимости курьерской доставки'
    },
    three: {
      label: 'Гедонист',
      label_alt: 'Гедониста',
      head_text: '10% от суммы заказа вернутся баллами',
      bonus: '500 бонусных баллов за переход на уровень',
      info: 'Гедонистом можно стать, когда сумма чеков достигнет 40 000 рублей без учета стоимости курьерской доставки'
    }
  },
  promo: {
    blockLoyalty: {
      front_title0: 'Вступайте',
      front_title1: 'в программу',
      front_title2: 'лояльности',
      front_title3: 'Maestrello',
      back_subtitle0: 'Копите и списывайте баллы и получите доступ',
      back_subtitle00: 'к специальным передложениям.',
      back_subtitle1: 'Бонус за регистрацию — 300 баллов.',
      back_subtitle2: '1 балл — 1 рубль.'
    },
    blockDiscount: {
      front_title0: 'Скидка на',
      front_title1: 'доставку 10%',
      front_subtitle0: 'с понедельника по четверг',
      back_subtitle0: 'На все заказы, оформленные',
      back_subtitle1: 'с понедельника по четверг',
      back_subtitle2: 'с 11:00 до 16:00.'
    },
    blockPickup: {
      front_title0: 'Ciao!',
      front_title1: 'Maestrello',
      front_title2: 'Pick-up Point',
      front_subtitle0: '*Маэстрелло Пик-ап Поинт',
      back_title0: 'Maestrello',
      back_subtitle0: 'Ваш помощник с бесконтактным',
      back_subtitle1: 'заказом хрустящей римской',
      back_subtitle2: 'пиццы, салатов, супов',
      back_subtitle3: 'и десертов без очереди.',
    },
    blockCertificate: {
      front_title0: 'Сертификат',
      front_title1: 'Maestrello',
      back_subtitle0: 'Самый вкусный подарок другу, коллеге или семье. Сертификат многоразовый и может быть использован на доставку и в наших пиццериях.',
      back_subtitle1: 'Приобрести сертификат можно онлайн в разделе меню «Сертификаты» или в наших пиццериях.'
    },
    blockSanta: {
      first_title: 'Новогодняя акция в поддержку малообеспеченных и многодетных семей.',
      first_title2: 'Станьте тайным Сантой вместе с нами. Новогодняя акция в поддержку малообеспеченных и многодетных семей.'
    },
    blockWorkTime: {
      front_title: 'Режим работы доставки в праздники',
      back_title: 'Режим работы доставки в праздники',
      descriptionDate1: '31 декабря',
      descriptionTime1: '11:00 - 16:30',
      descriptionDate2: '1 января выходной',
      descriptionTime2: '',
      descriptionDate3: '2 января',
      descriptionTime3: '14:00 - 21:30',
      descriptionDate4: '3 января',
      descriptionTime4: '12:00 - 21:30',
      descriptionDate5: '4 января',
      descriptionTime5: '12:00 - 21:30',
      descriptionDate6: '5 января',
      descriptionTime6: '12:00 - 21:30'
    },
    blockValentine: {
      front_title0: 'Will you be my',
      front_title1: 'Valentine?',
      back_title: 'Will you be my Valentine?',
      back_subtitle0: 'С 10 по 19 февраля вы можете добавить к заказу свечу и подсвечник от наших друзей из Home Owner.',
      back_subtitle1: 'Всё для романтического ужина можно найти в меню и корзине.',
    },
    blockMargherita: {
      front_title0: 'Мини-Маргарита',
      front_title1: 'в подарок',
      front_title2: '',
      back_subtitle0: 'К каждому заказу от 2000 руб. дарим мини - Маргариту.',
      back_subtitle1: 'Пицца автоматически появится в корзине при достижении',
      back_subtitle2: 'необходимой суммы заказа.'
    }
  },
  common: {
    account: 'Личный кабинет',
    login: 'Войти',
    delivery_menu: 'Меню доставки',
    aboutUs: 'О нас',
    delivery: 'Доставка',
    myOrders: 'Мои заказы',
    catering: 'Кейтеринг',
    legal: 'Больше информации',
    cart: 'Корзина',
    back: 'Назад',
    close: 'Закрыть',
    loading: 'Загрузка...',
    make_order: 'Оформить',
    sww: 'Что-то пошло не так',
    sww404: 'Что-то пошло не так, такой страницы у нас нет. Зато есть пицца',
    menuLink: 'Посмотреть пиццу',
    cookies: 'Файлы Cookies необходимы для правильной работы сайта. Мы используем файлы Сookies для обеспечения безопасности ввода данных на сайте, их сохранения, сбора статистики, оптимизации работы сайта и представления контента. Чтобы продолжить работу с сайтом, нажмите «Согласиться и продолжить». Вы можете ознакомиться с подробным описанием используемых типов файлов Сookies и принять решение о работе с ними по ссылке «Больше информации».',
    cookie_button_text: 'Согласиться и продолжить',
    language: 'Язык сайта',
    checkCode: 'Проверочный код',
    checkCodeAction: 'Проверить код',
    checkCodeError: 'Код не верный',
    checkCodeTimeout: 'Отправить код повторно через {{timeOut}} сек',
    repeatCheckCode: 'Отправить код повторно',
    phoneChecked: 'Телефон подтвержден',
    changePhoneNumber: 'изменить номер',
    agreement_processing: 'согласие на обработку персональных данных, ознакомление с пользовательским соглашением, политикой конфиденциальности',
    agreement_marketing: 'хочу получать уведомления о новинках и специальных предложениях',
    open: 'перейти'
  },
  dishes: {
    allergens: 'Аллергены: ',
    weight: 'Вес',
    protein: 'Белки: ',
    fat: 'Жиры: ',
    carbohydrates: 'Углеводы: ',
    g: 'г.',
    ml: 'мл.',
    kcal: 'ккал. на 100г.',
    addToCart: 'В корзину',
    addToPizza: 'Добавить в пиццу',
    close: 'Закрыть'
  },
  formValidation: {
    required: 'Обязательное поле',
    minlength7: 'Минимум 8 символов',
    incorrectAddress: 'Неверный адрес',
    incorrectFormat: 'Неверный формат',
    incorrectPhone: 'Неверный номер телефон',
    incorrectCode: 'Неверный код',
    incorrectNumber: 'Неверный номер',
    incorrectDateTime: 'Некорректные время/дата',
    incorrectDateTimeToEarly: 'Пожалуйста, выберите будущую дату',
    notPrecisionAddress: 'Неточный адрес',
    notFullAddress: 'Неполный адрес',
    specifyHouseNumber: 'Уточните номер дома.',
    specifyAddress: 'Уточните адрес.',
    incorrectEmailOrPhone: 'Некорректные адрел или телефон',
    notExact: 'Пароли не совпадают',
    passwordDifficult: 'Минимальная длинна пароля 8 символов и он должен содержать хотя бы одну заглавную буку, одну прописную, одну цифру и один спец символ',
    passwordLong: 'Минимальная длинна пароля 8 символов',
    passwordUpperCaseLetter: 'Пароль должне содержать хотя бы одну заглавную букву',
    passwordLowerCaseLetter: 'The password has at least one lowercase letter',
    passwordOneDigit: 'The password has at least one digit',
    passwordSpecCharacter: 'The password has at least one special character'
  },
  page: {
    close: 'Закрыть',
    apply: 'Готово',
    readLink: 'Читать',
    about: {
      phone: 'Телефон',
      email: 'Почта',
      socialNetworks: 'Социальные сети',
      instagram: 'инстаграм',
      telegram: 'телеграм',
      vkontakte: 'вконтакте',
      facebook: 'фейсбук',
      pizzeriaAddresses: 'Адреса пиццерий',
      pokrovka: 'Москва, Покровка, 16',
      mitnaya: 'Москва, Даниловский рынок, Мытная, 74',
      petrovka: 'Москва, Петровка, 30/7',
      gruzinka: 'Москва, Большая Грузинская, 76',
      depo: 'Москва, Три Вокзала. Депо, Новорязанская 23с1',
      ermolaevskiy: 'Moscow, Ермолаевский переулок, 17c1',
      deliveryHours: 'Режим работы доставки',
      readAboutUs: 'Читайте о нас',
      deliveryRules: 'Условия доставки',
      deliveryPrice: 'Стоимость доставки',
      deliveryMinPrice: 'Минимальная сумма заказа',
      deliveryMinPriceDescription: '700₽ без учёта стоимости доставки',
      deliveryTime: 'Время доставки',
      deliveryTimeDescription: 'Рассчитывается индивидуально во время оформления заказа',
      deliveryTimeDescriptionSms: 'После оформления заказа ожидайте СМС с подтверждением',
      MonThu: 'ПН-ЧТ',
      FriSat: 'ПТ-СБ',
      Sun: 'ВС',
      MonSun: 'ПН-ВС',
      MonFri: 'ПН-ПТ',
      SatSun: 'СБ-ВС',
      intro0: 'Мы Maestrello — сеть современных итальянских пиццерий, запущенная в 2017 году тремя итальянцами: Джулией Маэстрелло, Маттео Кастанья и Фабио Брессаном.',
      intro1: 'Главное отличие нашей римской пиццы – в ручной работе, тщательно отобранных ингредиентах и уникальном тесте. Мы смешиваем его из трех видов отборной муки: соевой, пшеничной, рисовой. Благодаря этому пицца получается легкой, а для еще одного кусочка всегда найдется место!'
    },
    legal: {
      links: 'Ссылки'
    },
    delivery: {
      address: 'Адрес доставки',
      notAvailable: 'Доставка не доступна по этому адресу',
      tooFar: 'Слишком далеко',
      dragMe: 'Перетащи меня',
      typeAddress: 'Начинайте вводить адрес',
      price: 'Стоимость доставки: {{price}} ₽',
      time: ' | ~ {{time}} мин.',
      deliveryTimeLine1: 'Режим работы доставки ',
      deliveryTimeLine2: 'ПН-ПТ 10:00-21:30',
      deliveryTimeLine3: 'СБ-ВС 11:00-21:30',
      descriptionLine1: 'Стоимость и время рассчитываются индивидуально в зависимости от того, в какой из трех зон находится адрес доставки. Минимальная сумма заказа составляет 700₽ без учёта стоимости доставки.',
      descriptionLine2: 'После оформления заказа ожидайте СМС с подтверждением.'
    },
    pickupPoint: {
      header: 'Ciao! Это Maestrello Pick-up Point (Маэстрелло Пик-ап Поинт) — ваш помощник с бесконтактным заказом хрустящей римской пиццы, салатов, супов и десертов без очереди. Воспользуйтесь опцией Maestrello Pick-up Point по дороге домой, на прогулке, по пути в парк или в гости к друзьям! Заранее сделайте заказ на сайте, выберите удобное время и заберите свою любимую пиццу в одной из специальных ячеек в новой пиццерии на Петровке 30/7. Легко, быстро и бесконтактно.',
      stage1: 'Заходите на сайт с помощью телефона или компьютера',
      stage2: 'Добавляйте заказ в корзину',
      stage3: 'Выбирайте опцию «Самовывоз» и пиццерию на Петровке 30/7',
      stage4: 'Указывайте удобное время и оплачивайте заказ (только безналичный расчет)',
      stage5: 'Получайте СМС с номером ячейки',
      stage6: 'Забирайте заказ из нужной ячейки',
      footer: 'При возникновении сложностей или вопросов на месте, обращайтесь к кассирам с данными из СМС.'
    },
    santa: {
      counter: 'Пицц уже куплено',
      header: 'Santa Pizza – рождественская акция в поддержку малообеспеченных и многодетных семей Басманного, Тверского и Даниловского районов. Акция проходит с 23 декабря по 7 января.',
      subheader: 'Что нужно сделать, чтобы стать тайным Сантой и подарить детям пиццу?',
      stage1: '— Выберите одну или несколько Санта-пицц в разделе меню «SantaPizza»',
      stage2: '— Оплатите половину стоимости пиццы, а вторую половину Maestrello возьмет на себя',
      stage3: '*если вы решили ничего не заказывать на сайте, вы все еще можете подарить Санта-пиццу! Для этого положите в корзину одну или несколько Санта-пицц и оформите самовывоз из любой пиццерии.',
      stage4: 'По окончанию акции мы отправим все Санта-пиццы, а также подарим радость и ваше внимание тем, кто в этом особенно нуждается.',
      stage5: 'За результатами волшебства следите в нашем insta: @pizzamaestrello',
    },
    conditions: {
      paymentsConditions: 'Условия оплаты',
      paymentsOptions: 'Способы оплаты',
      byCard: 'Банковской картой при самовывозе из ресторана',
      byCash: 'Наличными курьеру или банковской картой на сайте при заказе доставки',
      byInvoice: 'Безналичная оплата по счету',
      paymentText: 'Для оплаты банковской картой нажмите на кнопку «Оплата банковской картой» на странице оформления заказа. Оплата может быть произведена с использованием банковских кредитных карт следующих платежных систем:',
      dataTransfer: 'Передача данных',
      paymentText2: 'Для оплаты покупки Вы будете перенаправлены на платежный шлюз банка для ввода реквизитов Вашей карты. Пожалуйста, приготовьте Вашу пластиковую карту заранее. Соединение с платежным шлюзом и передача информации осуществляется в защищенном режиме с использованием протокола шифрования SSL.Конфиденциальность сообщаемой персональной информации обеспечивает Банк "Русский Стандарт". Введенная информация не будет предоставлена третьим лицам за исключением случаев, предусмотренных законодательством РФ. Проведение платежей по банковским картам осуществляется в строгом соответствии с требованиями платежных систем Visa Int. и MasterCard Europe Sprl.',
      paymentProcess: 'Процесс оплаты',
      paymentText3: 'При оплате с помощью банковской карты проведение платежа по заказу произойдет непосредственно после завершения оформления заказа в нашем online магазине. После подтверждения заказа нажмите на кнопку «Оплата банковской картой», при этом система переключит вас на страницу авторизационного сервера, где необходимо будет ввести данные карты, инициировать ее авторизацию, после чего вернуться в online магазин, нажав на кнопку "Вернуться в магазин". Затем система уведомит вас о результатах авторизации. При подтверждении авторизации мы автоматически начнем готовить ваш заказ. В случае отказа в авторизации карты попробуйте повторить процедуру оплаты.',
      orderCancellation: 'Аннулирование заказа',
      paymentText4: 'При аннулировании оплаченного заказа вы можете заказать другие товары из меню или получить возврат всей суммы на карту, связавшись с оператором по телефону +79166338777 или написав на e-mail: delivery@maestrello.ru'
    },
    auth: {
      login: 'Почта или телефон',
      password: 'Пароль',
      passwordCopy: 'Повторите пароль',
      incorrectValues: 'Неправильные данные',
      create: 'Создать аккаунт',
      enter: 'Войти',
      passwordLost: 'Забыл пароль',
      singUp: 'Регистрация',
      wasSingUp: 'Регистрировались?',
      firstName: 'Имя',
      lastName: 'Фамилия',
      name: 'Имя',
      birthday: 'Дата рождения',
      gender: 'Пол',
      gender_male: 'Мужской',
      gender_female: 'Женский',
      gender_not_selected: 'Пол не выбран',
      placeholderName: 'Антон',
      phone: 'Телефон',
      email: 'Почта',
      createAccount: 'Создать аккаунт',
      recoverPassword: 'Восстановить пароль',
      newPassword: 'Новый пароль',
      changePassword: 'Изменить пароль',
      changePasswordSuccess: 'Пароль успешно изменени.',
      smsCode: 'Код из смс',
      recover: 'Восстановить',
      emailOrPhone: 'Почта или телефон',
      registerError: 'Пользователь существует! Используйте восстановление пароля.',
      newPasswordSendTo: 'На {{address}} направлена ссылка на смену пароля.',
      time: '{{time}} минут',
      address_save_btn: 'Да, верно!'
    },
    account: {
      addAddress: 'Добавить адрес',
      orders: 'Заказы',
      viewAllOrders: 'Посмотреть все',
      totalOrders: 'Всего {{total}} заказов',
      repeatOrder: 'Повторить',
      logOut: 'Выйти из аккаунта',
      rulesLevels: 'Условия уровней и бонусов',
      addresses: 'Мои адреса',
      points: 'Мои баллы',
      point: 'балл',
      address: 'Адрес',
      entrance: 'Подъезд ',
      intercom: 'Домофон ',
      floor: 'Этаж',
      apartment: 'Квартира ',
      comment: 'Комментарий',
      addressString: '{{entrance}} Этаж {{floor}}{{apartment}}{{intercom}}{{comment}}',
      changeInfo: 'Изменить данные', /* deprecated */
      deleteAddress: 'Удалить адрес',
      allOrders: 'Все заказы',
      addCard: 'Добавить карту',
      toWallet: 'в Wallet',
      addToWallet: 'Добавить в Wallet',
      fillProfileFields: 'Заполните данные профиля и установите электронную карту в Wallet для участия в программе лояльности',
      fillProfileFieldsAction: 'Перейти к профилю.'
    },
    cart: {
      link: 'Корзина',
      empty1: 'В корзине пока что ничего нет.',
      empty2: 'Добавим что-нибудь?',
      shippingMenu: 'Меню доставки',
      free: 'Бесплатно',
      numberOfCutlery: 'Количество приборов',
      giftInBasket: 'Подарок в корзине',
      pizzaAsGift: 'Мини пицца в подарок',
      addToOrder: 'Добавить к заказу',
      desertGiftFriend: 'Десерт в подарок за приведенного друга',
      desertGiftLevelChange: 'Поздравляем и дарим десерт или морс на выбор в подарок!',
      pizzaGiftLevelChange: 'Поздравляем и дарим одну из пяти пицц в подарок!',
      miniAdded: 'Мини Маргарита добавлена в корзину. Приятного аппетита!',
      miniToAdd: 'Вам не хватает {{price}} ₽ до подарка. Добавим что-нибудь к заказу?',
      additional: 'Дополнительно:',
      addIngredients: 'Добавить ингредиенты',
      checkout: 'Оформить заказ',
      loyalty: 'Программа лояльности',
      romanticDinner: 'Всё для романтического ужина',
    },
    order: {
      orderNum: 'Заказ №',
      status: 'Статус:',
      readyTime: 'Будет готов к ',
      deliveryTime: 'Доставим к ',
      goToPayment: 'Оплатить',
      saveAddress: 'Сохранить адрес',
      notSaveAddress: 'Нет, спасибо',
      saveAddressText: 'Хотите сохранить адрес? Так вы сможете быстрее заказать пиццу в следующий раз.',
      shippingNotAvailable: 'по этому адресу недоступна.',
      delivery: 'Доставка',
      deliveryTimeCost: 'от {{min}} мин / {{cost}} ₽',
      deliveryCourier: 'Курьер',
      deliveryPickup: 'Заберу сам',
      address: 'Адрес (Улица с номером дома)*',
      entrance: 'Подъезд*',
      intercom: 'Домофон',
      floor: 'Этаж*',
      apartment: 'Квартира',
      comment: 'Комментарий',
      pickupPokrovka: 'Покровка, 16',
      pickupMitnaya: 'Мытная, 74',
      pickupGruzinka: 'Большая Грузинская, 76',
      pickupPetrovka: 'Петровка, 30/7',
      pickupDepo: 'Новорязанская, 23с1',
      pickupErmolaevskiy: 'Ермолаевский переулок, 17c1',
      selectReadyTime: 'Время готовности',
      selectDeliveryTime: 'Время доставки',
      soonAsPossible: 'В ближайшее время',
      selectTime: 'Указать время',
      date: 'Дата',
      time: 'Время',
      min: '{{minutes}} мин',
      makeOrder: 'Заказать',
      deliveryNotWork: 'Сейчас наша доставка не работает, но вы можете сделать предзаказ или воспользоваться сервисом Яндекс.Еда.',
      kitchenClosed: 'Сейчас кухня не работает, но вы можете сделать предзаказ.',
      minimumDelivery: 'Доставка доступна для заказов на сумму от 700₽ без учёта доставки.',
      orderTotal: 'Сумма заказа: {{cost}} ₽',
      orderNew: 'Не оплачен',
      orderPaid: 'Оплачен',
      orderProcessing: 'Подтвержден',
      orderForwarded: 'Отправлен в ресторан',
      orderAccepted: 'Принят рестораном',
      orderReadyToPickup: 'Приготовлен',
      orderAwaitingPickup: 'Ожидает самовывоза',
      orderShipped: 'Отправлен',
      orderDelivered: 'Доставлен',
      orderCompleted: 'Выполнен',
      orderCanceled: 'Отменен',
    },
    payment: {
      name: 'Оплата',
      options: 'Оплата',
      conditions: 'Условия',
      byCard: 'Банковской картой',
      byCardTest: 'Банковской картой Тест',
      byCash: 'Наличными',
      byCashSecondLine: 'При получении',
      byInvoice: 'По счету',
      byInvoiceSecondLine: 'Безналичная оплата (для юридических лиц)',
      change: 'С какой суммы нужна сдача?*',
      discount: 'Промокод',
      checkPromo: 'Проверить код',
      promoActivated: 'Код активирован',
      promoInvalid: 'Код недействителен',
      confirmByCard: 'Оплатить',
      confirmByCash: 'Оформить',
      pay: 'Оплатить',
      checkout: 'Оформить',
      optionDiscount: 'Воспользоваться скидкой 5% за предварительный заказа',
      optionSavePoints: 'Накопить баллы',
      optionWriteOffPoints: 'Списать баллы',
      point_zero: '{{count}} баллов',
      point_one: '{{count}} балл',
      point_few: '{{count}} балла',
      point_many: '{{count}} баллов',
      point_other: '{{count}} баллов',
      writeOffDescription: 'Оплатить до {{count}}% суммы заказа баллами'
    }
  }
};
