import React, { useState, useEffect } from 'react';
import {Route, Routes as BaseRoutes, useLocation} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import {useUserInfo, useAuthorized, resetUserSuccess, getProfile, getOrders, useUserToken} from './ducks/auth';
import { cutlerySelector } from './ducks/cart';
import {clearOrder, showAboutPickupModalSelector, toggleAboutPickupModal} from './ducks/order';
import { orderSelector } from './ducks/order';
import Header from './components/common/header';
import Footer from './components/common/footer';
import CartLink from './components/common/cart-link';
import Dish from './components/dishes/dish';
import PaymentConditions from './components/orders/payment-conditions';
import AboutPickup from "./components/common/about-pickup";

import ForgotFormPage from './components/auth/forgot-form-page';
import RegisterFormPage from './components/auth/register-form-page';
import AllOrders from './components/orders/all-orders';
import PaymentForm from './components/orders/payment-form';
import { getUserPhone, PRODUCTS } from './utils';
import './style.js';
import OrderCreated from './components/orders/order-created';
import amplitude from 'amplitude-js';
import CookieConsent from "react-cookie-consent";
import ReactPixel from 'react-facebook-pixel';
import { useTranslation } from "react-i18next";
import {showSantaModalSelector, toggleSantaModal} from "./ducks/santa";
import SantaPage from "./components/common/santa";
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';
import PaymentWidget from "./components/orders/payment-widget";

import { ProtectedRoute } from "./routes/protected-route";
import HomePage from './components/routes/home';
import NotFoundPage from './components/routes/not-found';
import AboutPage from "./components/pages/about";
import LegalPage from './components/pages/legal';
import DeliveryPage from "./components/pages/delivery";
import AccountPage from "./components/account/account";
import OrderPage from './components/orders/order';
import CartPage from './components/cart/cart';
import LoginFormPage from "./components/auth/login-form-page";
import EditAccountPage from "./components/account/edit-account";
import PaymentPage from "./components/orders/payment";
import PaymentConditionsPage from "./components/orders/payment-conditions";
import EditAddressPage from "./components/account/edit-address";
import AddAddressWithMapPage from "./components/account/add-address-with-map";
import NotificationModal from "./components/common/nitification-modal";
import NewPasswordFormPage from "./components/auth/new-password-form-page";
import OrderStatusPage from "./components/orders/order-status";


function sendFBPixelEvent(event, params) {
  ReactPixel.trackCustom(event, params);
}

function sendYamEvent(event, params) {
  if (event === 'hit') {
    ym('hit', params);
  } else {
    // ym('reachGoal', 'whateverGoal', {awesomeParameter: 42});
  }
}

function App() {
  const { t } = useTranslation();
  const location = useLocation();
  const isAuthorized = useAuthorized();
  const orderData = useSelector(orderSelector);
  const showSantaModal = useSelector(showSantaModalSelector);
  const showAboutPickupModal = useSelector(showAboutPickupModalSelector);
  const userInfo = useUserInfo();
  const userPhone = getUserPhone(userInfo);
  const userToken = useUserToken();

  const dispatch = useDispatch();
  const emptyDish = { id: '', name: '', summary: '', price: 0, empty: true };

  const [loginFlag, toggleLogin] = useState(false);
  const [registerFlag, toggleRegister] = useState(false);
  const [recoverFlag, toggleRecover] = useState(false);

  const [about, toggleAbout] = useState(false);
  const [legal, toggleLegal] = useState(false);
  const [delivery, toggleDelivery] = useState(false);

  const [account, toggleAccount] = useState(false);
  const [editAccount, toggleEditAccount] = useState(false);
  const [editAddress, toggleEditAddress] = useState(false);
  const [addAddress, toggleAddAddress] = useState(false);

  const [allOrders, toggleAllOrders] = useState(false);

  const [dish, selectDish] = useState(emptyDish);
  const closeDish = () => selectDish({ ...dish, empty: true });
  const dishIsSelected = !dish.empty;

  const [cart, toggleCart] = useState(false);
  const [order, toggleOrder] = useState(false);
  const [payment, togglePayment] = useState(false);
  const [conditions, toggleConditions] = useState(false);

  const [orderCreated, toggleOrderCreated] = useState(false);

  const cutlery = useSelector(cutlerySelector);

  const openLoginFunc = (inCart) => {
    dispatch(resetUserSuccess());

    closeRegisterFunc();
    closeRecoverFunc();

    closeDish();

    closeAboutFunc();
    closeDeliveryFunc();

    if (!inCart) {
      closeCartFunc();
      closeOrderFunc();
      closePaymentFunc();
    }

    closeAccountFunc();
    closeEditAccountFunc();
    closeEditAddressFunc();
    closeAddAddressFunc();
    closeAllOrdersFunc();
    closeAboutPickupFunc();
    closeOrderCreatedFunc();

    toggleLogin(true);
  }

  const closeLoginFunc = () => {
    toggleLogin(false);
  }

  const openRegisterFunc = () => {
    dispatch(resetUserSuccess());
    toggleRegister(true);
  }

  const closeRegisterFunc = () => {
    toggleRegister(false);
    dispatch(resetUserSuccess());
  }

  const openRecoverFunc = () => {
    dispatch(resetUserSuccess());
    toggleRecover(true);
  }

  const closeRecoverFunc = () => {
    toggleRecover(false);
    dispatch(resetUserSuccess());
  }

  const closeAboutFunc = () => {
    toggleAbout(false);
  }

  const closeLegalFunc = () => {
    toggleLegal(false);
  }

  const closeDeliveryFunc = () => {
    toggleDelivery(false);
  }

  const closeAboutPickupFunc = () => {
    dispatch(toggleAboutPickupModal(false));
  }

  const closeSantaFunc = () => {
    dispatch(toggleSantaModal(false));
  }

  const closeAccountFunc = () => {
    toggleAccount(false);
  }

  const closeEditAccountFunc = () => {
    toggleEditAccount(false);
  }

  const closeEditAddressFunc = () => {
    toggleEditAddress(false);
    dispatch(resetUserSuccess());
  }

  const closeAddAddressFunc = () => {
    toggleAddAddress(false);
    dispatch(resetUserSuccess());
  }

  const closeAllOrdersFunc = () => {
    toggleAllOrders(false);
  }

  const selectDishFunc = (d, id) => {
    selectDish({ ...d, cart_item: id, empty: false });
  }

  const openCartFunc = () => {
    closeLoginFunc();
    closeRegisterFunc();
    closeRecoverFunc();

    closeDish();

    closeAboutFunc();
    closeDeliveryFunc();

    closeOrderFunc();
    closePaymentFunc();

    closeAccountFunc();
    closeEditAccountFunc();
    closeEditAddressFunc();
    closeAddAddressFunc();
    closeAllOrdersFunc();

    toggleCart(true);
  }

  const closeCartFunc = () => {
    toggleCart(false);
  }

  const openOrderFunc = () => {
    toggleOrder(true);
  }

  const closeOrderFunc = () => {
    toggleOrder(false);
  }

  const closePaymentFunc = () => {
    togglePayment(false);
  }

  const closeConditionsFunc = () => {
    toggleConditions(false);
  }

  const openOrderCreatedFunc = () => {
    closeCartFunc();
    closeOrderFunc();
    closePaymentFunc();
    toggleOrderCreated(true);
  }

  const closeOrderCreatedFunc = () => {
    toggleOrderCreated(false);
    dispatch(clearOrder());
  }

  const handleClickOutside = () => {
    // setCloseModals(true);
    // setTimeout(() => {
    //   setCloseModals(false);
    // }, 200);
    if (showAboutPickupModal) { closeAboutPickupFunc(); }
    if (showSantaModal) { closeSantaFunc(); }
    if (orderCreated) { closeOrderCreatedFunc(); }
    if (dishIsSelected) { closeDish(); }
  }

  useEffect(() => {
    if (location.pathname !== '/' || orderCreated || dishIsSelected) {
      document.documentElement.style.overflow = 'hidden';
      document.body.style.overflow = 'visible';
      document.body.style.height = '100%';
    } else if (location.pathname === '/' && !dishIsSelected) {
      document.documentElement.style.overflow = 'visible';
      document.body.style.overflow = 'visible';
      document.body.style.height = 'auto';
    }
  }, [location, orderCreated, dishIsSelected]);

  useEffect(() => {
    if (isAuthorized && userToken) {
      dispatch(getProfile({ token: userToken }));
      dispatch(getOrders({ token: userToken }));
    }
  }, [isAuthorized, userToken]);


  useEffect(() => {
    if (about) {
      amplitude.getInstance().logEvent('button_main_menu_delivery');
      sendFBPixelEvent('button_main_menu_delivery');
      sendYamEvent('hit', '/about');
    }
  }, [about]);

  useEffect(() => {
    if (legal) {
      amplitude.getInstance().logEvent('button_main_menu_legal');
      sendFBPixelEvent('button_main_menu_legal');
      sendYamEvent('hit', '/legal');
    }
  }, [legal]);

  useEffect(() => {
    if (account) {
      amplitude.getInstance().logEvent('button_profile');
      sendFBPixelEvent('button_profile');
      sendYamEvent('hit', '/account');
    }
  }, [account]);

  useEffect(() => {
    if (loginFlag) {
      amplitude.getInstance().logEvent('button_profile');
      sendFBPixelEvent('button_profile');
      sendYamEvent('hit', '/login');
    }
  }, [loginFlag]);

  useEffect(() => {
    if (!dish.empty) {
      amplitude.getInstance().logEvent('menu_open_dish', {
        dish_name: PRODUCTS[dish.id]
      });
      sendFBPixelEvent('menu_open_dish', {
        dish_name: PRODUCTS[dish.id]
      });
    }
  }, [dish.empty]);

  useEffect(() => {
    if (cart) {
      amplitude.getInstance().logEvent('button_menu_check');
      sendFBPixelEvent('button_menu_check');
      sendYamEvent('hit', '/cart');
    }
  }, [cart]);

  useEffect(() => {
    if (order) {
      amplitude.getInstance().logEvent('button_cart_persons', {
        cutlery_numb: cutlery
      });
      amplitude.getInstance().logEvent('button_cart_order');
      sendFBPixelEvent('button_cart_order');
      sendYamEvent('hit', '/order');
    }
  }, [order]);

  useEffect(() => {
    if (payment) {
      let orderDelivery = '';
      let orderAddress = '';

      if (orderData.pickup === 'mitnaya') {
        orderDelivery = 'pick_up_mytnaya';
      } else if (orderData.pickup === 'pokrovka') {
        orderDelivery = 'pick_up_pokrovka';
      } else if (orderData.pickup === 'petrovka') {
        orderDelivery = 'pick_up_petrovka';
      } else if (orderData.pickup === 'ermolaevskiy') {
        orderDelivery = 'pick_up_ermolaevskiy';
      } else {
        orderDelivery = 'courier';

        if (orderData.intercom) {
          orderAddress = `${orderData.address}, ${orderData.entrance}, ${orderData.intercom}, ${orderData.floor}, ${orderData.appartment}`;
        } else {
          orderAddress = `${orderData.address}, ${orderData.entrance}, ${orderData.floor}, ${orderData.appartment}`;
        }
      }

      amplitude.getInstance().logEvent('checkout_delivery', {
        delivery_type: orderDelivery
      });

      sendFBPixelEvent('checkout_delivery', {
        delivery_type: orderDelivery
      });

      sendYamEvent('hit', '/payment');

      if (orderAddress) {
        amplitude.getInstance().logEvent('checkout_address', {
          order_address: orderAddress
        });

        sendFBPixelEvent('checkout_address', {
          order_address: orderAddress
        });
      }

      if (orderData.comment) {
        amplitude.getInstance().logEvent('checkout_comments', {
          order_comments: orderData.comment
        });

        sendFBPixelEvent('checkout_comments', {
          order_comments: orderData.comment
        });
      }
    }
  }, [payment]);

  const dishInCart = cart && dishIsSelected;
  const formBgClassnames = classNames({
    'app__form-bg': true,
    'app__form-bg--visible': dishIsSelected || orderCreated || showAboutPickupModal || showSantaModal,
    'app__form-bg--visible-dish-in-cart': dishInCart,
    'app__form-bg--visible-login-in-payment': (order && loginFlag) || orderData.showAboutPickupModal
  });

  const closeAll = (force) => {
    if (dishIsSelected) {
      closeDish();
    } else if (order && loginFlag) {
      closeLoginFunc();
    } else if (orderCreated) {
      closeOrderCreatedFunc();
    } else if (orderData.showAboutPickupModal && !force) {
      closeAboutPickupFunc();
    } else if (payment && conditions) {
      closeConditionsFunc();
    } else if (showSantaModal) {
      closeSantaFunc();
    } else {
      closeLoginFunc();
      closeRegisterFunc();
      closeRecoverFunc();

      closeDish();

      closeAboutFunc();
      closeLegalFunc();
      closeDeliveryFunc();
      closeAboutPickupFunc();

      closeCartFunc();
      closeOrderFunc();
      closePaymentFunc();

      closeAccountFunc();
      closeEditAccountFunc();
      closeEditAddressFunc();
      closeAddAddressFunc();
      closeAllOrdersFunc();
    }
  }

  const [closeModals, setCloseModals] = useState(false);

  return (
    <div className='app'>
      <YMInitializer accounts={[66588439]} options={{webvisor: true}} version="2" />
      <Header closeDish={ closeDish } />

      <BaseRoutes>
        <Route path='*' element={ <NotFoundPage/> } />
        <Route path='/' element={ <HomePage selectDishFunc={ selectDishFunc } closeModals={ closeModals } /> }>
          <Route path='/about' element={ <AboutPage></AboutPage> } />
          <Route path='/legal' element={ <LegalPage></LegalPage> } />
          <Route path='/delivery' element={ <DeliveryPage></DeliveryPage> } />
          <Route path='/login' element={ <LoginFormPage></LoginFormPage> } />
          <Route path='/forgot' element={ <ForgotFormPage></ForgotFormPage> } />
          <Route path='/new-password/:hash' element={ <NewPasswordFormPage></NewPasswordFormPage> } />

          <Route path='/register' element={ <RegisterFormPage></RegisterFormPage> } />
          <Route path='/register/:referer' element={ <RegisterFormPage></RegisterFormPage> } />


          <Route path='/account' element={
            <ProtectedRoute user={userInfo}>
              <AccountPage/>
            </ProtectedRoute> }
          />

          <Route path='/account/edit' element={
            <ProtectedRoute user={userInfo}>
              <EditAccountPage/>
            </ProtectedRoute> }
          />

          <Route path='/account/edit-address/:id' element={
            <ProtectedRoute user={userInfo}>
              <EditAddressPage/>
            </ProtectedRoute> }
          />

          <Route path='/account/add-address' element={
            <ProtectedRoute user={userInfo}>
              <AddAddressWithMapPage/>
            </ProtectedRoute> }
          />

          <Route path='/cart' element={ <CartPage selectDishFunc={selectDishFunc} /> }/>
          <Route path='/order' element={ <OrderPage/> }/>
          <Route path='/order/status/:id' element={ <OrderStatusPage/> }
          />
          <Route path='/payment' element={ <PaymentPage/> }/>
          <Route path='/payment/conditions' element={ <PaymentConditionsPage/> }/>

        </Route>
      </BaseRoutes>

      <CartLink closeDish={ closeDish } />
      <Footer />
      <Dish
        dish={dish}
        closeDish={ closeDish }
        selectDishFunc={selectDishFunc}
        orderCreated={orderCreated}
      />
      {
        !isAuthorized || !account ? null :
        <AllOrders
          allOrders={allOrders}
          closeAllOrdersFunc={closeAllOrdersFunc}
          openCartFunc={openCartFunc}
          closeAll={closeAll}
        />
      }
      <SantaPage
        show={showSantaModal}
        closeAll={closeAll}
      />
      {
        !payment ? null :
        <PaymentConditions
          conditions={conditions}
          closeConditionsFunc={closeConditionsFunc}
          closeAll={closeAll}
        />
      }
      <AboutPickup
        show={showAboutPickupModal}
        closeAll={ closeAll }
      />
      <NotificationModal/>
      <OrderCreated
        orderCreated={orderCreated}
        openOrderCreatedFunc={openOrderCreatedFunc}
        closeOrderCreatedFunc={closeOrderCreatedFunc}
        closeAll={closeAll}
        openLoginFunc={openLoginFunc}
      />
      <div className={formBgClassnames} onClick={ handleClickOutside }><span className='app__form-bg-line'></span></div>
      {
          !orderData.payment ? null :
              <PaymentForm paymentData={orderData.payment} />
      }
      {
          !orderData.payment_v2 ? null :
              <PaymentWidget paymentData={orderData.payment_v2} />
      }
      <CookieConsent
        location="bottom"
        buttonText={t('common.cookie_button_text')}
        cookieName="myAwesomeCookieName2"
        style={{ background: 'var(--m-black)', fontFamily: 'Geometria Narrow', alignItems: 'center' }}
        buttonStyle={{ color: '#000', fontSize: '14px', borderRadius: '30px', height: '40px', padding: '0 20px' }}
        expires={150}
      >
        {t('common.cookies')}
      </CookieConsent>
    </div>
  );
}

export default App;
