import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { ReactComponent as CloseSmallIcon } from '../../svg/close-36.svg';
import { ReactComponent as TelegramLogo } from '../../svg/telegram-logo.svg';
import { ReactComponent as VKLogo } from '../../svg/vk-logo.svg';
import { ReactComponent as ReadIcon } from '../../svg/read-grey.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router";
import { useLocation } from "react-router-dom";

function AboutPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hideChild, setHideChild]= useOutletContext();
  const [showModal, setShowModal] = useState(false);

  const aboutClassnames = classNames({
    'about modal modal--no-padding': true,
    'modal--visible': showModal
  });

  const headerClassnames = classNames({
    'modal-header': true,
    'modal-header--visible': showModal
  });

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  const links = [
    {
      logo: 'https://pizzamaestrello.com/img/media/logo-sisoev-fm.png',
      text: '14 вопросов владельцам Pizza Maestrello',
      link: 'https://dzen.ru/a/Y473yC9oLQAR0hC8',
    },
    {
      logo: 'https://pizzamaestrello.com/img/media/logo-localkitchen.png',
      text: '6 вопросов Джулии Маэстрелло',
      link: 'https://kak.media/maestrello/',
    },
    {
      logo: 'https://pizzamaestrello.com/img/media/logo-afisha-daily.png',
      text: 'PIZZA MAESTRELLO: пицца, за которой стоит очередь',
      link: 'https://daily.afisha.ru/eating/6678-pinsa-maestrello-picca-za-kotoroy-stoit-ochered/',
    },
    {
      logo: 'https://pizzamaestrello.com/img/media/logo-afisha.png',
      text: 'Pizza Maestrello: шикарный римский фастфуд на Покровке',
      link: 'https://www.afisha.ru/restaurant-news/pinsa-maestrello-shikarnii-rimskii-fastfud-na-pokrovke/',
    },
    {
      logo: 'https://pizzamaestrello.com/img/media/logo-forbes-life.png',
      text: 'Стоит съесть: пиццу в Pizza Maestrello',
      link: 'https://www.forbes.ru/forbeslife-photogallery/350817-stoit-sest-shcheku-konya-v-selfie-file-zayca-v-aviatore-pinsu-v-pinsa?photo=5',
    },
    {
      logo: 'https://pizzamaestrello.com/img/media/logo-village.png',
      text: 'Настоящая итальянская пиццерия Pizza Maestrello',
      link: 'https://www.the-village.ru/food/place/281782-novoe-mesto-pinsa-maestrello',
    }
  ];

  return (
      <div className={aboutClassnames}>
        <div className={headerClassnames}>
          <div className='modal-header__back base-header'>
            {t('common.aboutUs')}
          </div>
          <div className='modal-header__mobile-close'>
            <button className='effect' onClick={handleCloseModal}>
              <CloseSmallIcon title='Закрыть' />
            </button>
          </div>
          <div className='modal-header__close'>
            <button className='effect' onClick={handleCloseModal}>
              <CloseIcon title='Закрыть'/> {t('page.close')}
            </button>
          </div>
        </div>

        <div className='modal__wrapper'>
          <div className='about__top-info'>
            <div className='about__connect'>
              <div className='about__contacts'>
                <p className='base-text'>
                  {t('page.about.intro0')}
                </p>
                <p className='base-text'>
                  {t('page.about.intro1')}
                </p>
              </div>
              <div className='about__contacts'>
                <div className='about__half-block'>
                  <div className='about__subheader base-subheader'>
                    {t('page.about.phone')}
                  </div>
                  <div>
                    <a href='tel:+74958466337' className='base-text effect3'>
                      +7 495 846-63-37
                    </a>
                  </div>
                </div>
                <div className='about__half-block'>
                  <div className='about__subheader base-subheader'>
                    {t('page.about.email')}
                  </div>
                  <div>
                    <a href='mailto:info@maestrello.com' className='base-text effect3'>
                      info@maestrello.ru
                    </a>
                  </div>
                </div>
              </div>
              <div className='about__social'>
                <div className='about__subheader-social base-subheader'>
                  {t('page.about.socialNetworks')}
                </div>
                <div className='about__half-block base-text'>
                  <a href='https://t.me/p_maestrello' className='about__social-link base-text effect3 no-flick' target='_blank' rel='noreferrer'>
                    <TelegramLogo title='Телеграм' /> <span>{t('page.about.telegram')}</span>
                  </a>
                </div>
                <div className='about__half-block'>
                  <a href='https://vk.com/p_maestrello' className='about__social-link base-text effect3 no-flick' target='_blank' rel='noreferrer'>
                    <VKLogo title='Вконтакте' /> <span>{t('page.about.vkontakte')}</span>
                  </a>
                </div>
              </div>
            </div>

            <div className='about__addresses'>
              <div className='about__addresses-header base-header'>
                {t('page.about.pizzeriaAddresses')}
              </div>

              <div className='about__address'>
                <div className='about__subheader base-subheader'>
                  {t('page.about.gruzinka')}
                </div>
                <div className='base-text-grey'>
                  {t('page.about.MonSun')} 10:00-23:00
                </div>
              </div>

              <div className='about__address'>
                <div className='about__subheader base-subheader'>
                  {t('page.about.mitnaya')}
                </div>
                <div className='base-text-grey'>
                  {t('page.about.MonSun')} 10:00-22:00
                </div>
              </div>

              <div className='about__address'>
                <div className='about__subheader base-subheader'>
                  {t('page.about.pokrovka')}
                </div>
                <div className='base-text-grey'>
                  {t('page.about.MonThu')}; {t('page.about.Sun')} 11:00-23:00 <br/>
                  {t('page.about.FriSat')} 11:00-00:00 <br/>
                </div>
              </div>

              <div className='about__address'>
                <div className='about__subheader base-subheader'>
                  {t('page.about.depo')}
                </div>
                <div className='base-text-grey'>
                  {t('page.about.MonThu')}; {t('page.about.Sun')} 10:00-23:00 <br/>
                  {t('page.about.FriSat')} 10:00-00:00 <br/>
                </div>
              </div>

              <div className='about__address'>
                <div className='about__subheader base-subheader'>
                  {t('page.about.ermolaevskiy')}
                </div>
                <div className='base-text-grey'>
                  {t('page.about.MonSun')} 10:00-22:00 <br/>
                </div>
              </div>

              <div>
                <div className='about__subheader base-subheader'>
                  {t('page.about.deliveryHours')}
                </div>
                <div className='base-text-grey'>
                  {t('page.about.MonFri')} 10:00-21:30<br/>
                  {t('page.about.SatSun')} 11:00-21:30
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className='about__read-header base-header'>
              {t('page.about.readAboutUs')}
            </div>
            <div className='about__press-blocks'>
              {
                links.map((l, i) => {
                  const { text, logo, link } = l

                  return (
                      <a key={i} href={link} className='about__press' target='_blank' rel='noreferrer'>
                        <div className='about__press-header'>
                          <img src={logo} width='150' alt='LOGO' />
                          <div className='about__press-link'>
                        <span className='effect3 base-text no-flick'>
                          <ReadIcon title='Читать' /> <span>{t('page.readLink')}</span>
                        </span>
                          </div>
                        </div>
                        <div className='about__press-text base-header'>
                          {text}
                        </div>
                      </a>
                  )
                })
              }
            </div>
          </div>

        </div>
      </div>
  );
}

export default AboutPage;
